<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-LoHangTapTinDinhKemDetail" data-bs-toggle="tab"
                href="#LoHangTapTinDinhKemDetail" role="tab" aria-controls="LoHangTapTinDinhKemDetail"
                aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">Nhà cung cấp sản phẩm [{{LoHangTapTinDinhKemService.FormData.ID}}]</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><svg xmlns="http://www.w3.org/2000/svg"
                        width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
                        <path
                            d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="LoHangTapTinDinhKemDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-12 col-sm-12 col-12">                   
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="LoHangTapTinDinhKemService.IsShowLoading"></app-loading>